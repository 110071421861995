.App {
  text-align: center;
  overflow: hidden;
}

.App-logo {
  height: 68pt;
  /*height: 15vmin;*/
  pointer-events: none;
}

.App-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.App-text {
  color: #cecece;
  font-size: 24pt;
  font-weight: 600;
}

.App-header {
  background-color: #1c1c1e;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow: hidden;
  padding: 10px;
}

.App-link {
  color: #61dafb;
}

.App-slogan {
  font-size: 16pt;
  font-style: italic;
  color: #c9c9c9;
  font-weight: 300;
  margin-bottom: 20pt;
}

.App-bg {
}
/*.App-bg {*/
/*  position: absolute;*/
/*  bottom: 0;*/
/*  right: 0;*/
/*  height: 100px;*/
/*  width: 325px;*/
/*  background-color: #F66969;*/
/*  border-radius: 25px 0 0 0;*/
/*}*/

/*.App-bg::before {*/
/*  content: "";*/
/*  position: absolute;*/
/*  background-color: #f66969;*/
/*  height: 100px;*/
/*  width: 125px;*/
/*  border-radius: 25px 0 0 0;*/
/*  top: -25px;*/
/*  left: 100px;*/
/*}*/

#error-page {
  color: #c4c4c4;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
