.text {
  font-size: 12pt;
}

.title {
  font-size: 14pt;
  font-weight: 600;
}

.policyWrapper {
  margin: 0 auto;
  width: 80%;
  text-align: left;
}

li {
    margin: 10px 0;
}
